export const conversionProvince = {
  新疆维吾尔自治区: [87.6285, 43.7933],
  湖北省: [114.3415, 30.5462],
  辽宁省: [123.435, 41.8367],
  广东省: [113.2668, 23.1333],
  内蒙古自治区: [111.7652, 40.8182],
  黑龙江省: [126.6619, 45.7422],
  河南省: [113.753, 34.767],
  山东省: [117.0207, 36.6702],
  陕西省: [108.9539, 34.2666],
  贵州省: [106.7052, 26.6003],
  上海市: [121.4648, 31.2891],
  重庆市: [107.7539, 30.1904],
  西藏自治区: [91.1174, 29.6486],
  安徽省: [117.3301, 31.7345],
  福建省: [119.2961, 26.101],
  湖南省: [112.9829, 28.116],
  海南省: [110.3487, 20.0186],
  江苏省: [118.7635, 32.0613],
  青海省: [101.7804, 36.6225],
  广西壮族自治区: [108.3275, 22.8166],
  宁夏回族自治区: [106.2588, 38.4722],
  浙江省: [120.1525, 30.2666],
  河北省: [114.5303, 38.0377],
  香港特别行政区: [114.1733, 22.32],
  台湾省: [121.509, 25.0443],
  澳门特别行政区: [113.549, 22.1989],
  甘肃省: [103.8267, 36.0606],
  四川省: [104.0764, 30.6516],
  天津市: [117.2015, 39.0853],
  江西省: [115.8165, 28.6372],
  云南省: [102.7093, 25.0464],
  山西省: [112.5787, 37.8139],
  北京市: [116.4073, 39.9041],
  吉林省: [125.3258, 43.8969]
}

export const conversionCity = {
  铁门关市: [85.6702, 41.8629],
  双河市: [82.3536, 44.8405],
  北屯市: [87.8344, 47.3267],
  博尔塔拉蒙古自治州: [82.0663, 44.906],
  昌吉回族自治州: [87.3089, 44.011],
  阿勒泰地区: [88.1412, 47.8449],
  塔城地区: [82.9803, 46.7453],
  可克达拉市: [80.9941, 43.9403],
  昆玉市: [79.2701, 37.2153],
  石河子市: [86.0803, 44.3053],
  和田地区: [79.9216, 37.1144],
  五家渠市: [87.5428, 44.1664],
  巴音郭楞蒙古自治州: [86.1452, 41.7641],
  伊犁哈萨克自治州: [81.3236, 43.9171],
  哈密市: [93.515, 42.8193],
  喀什地区: [75.9897, 39.4704],
  阿克苏地区: [80.265, 41.1707],
  克孜勒苏柯尔克孜自治州: [76.1673, 39.7147],
  阿拉尔市: [81.2805, 40.5472],
  图木舒克市: [79.0749, 39.8677],
  克拉玛依市: [84.8892, 45.5777],
  胡杨河市: [84.8275, 44.6928],
  乌鲁木齐市: [87.6168, 43.8253],
  吐鲁番市: [89.1903, 42.9507],
  襄阳市: [112.1217, 32.0101],
  十堰市: [110.7989, 32.629],
  宜昌市: [111.2869, 30.6921],
  武汉市: [114.3045, 30.5933],
  荆门市: [112.1990, 31.0354],
  潜江市: [112.9002, 30.4019],
  天门市: [113.1665, 30.6637],
  咸宁市: [114.3226, 29.8413],
  仙桃市: [113.4429, 30.3284],
  孝感市: [113.9569, 30.9183],
  黄冈市: [114.8724, 30.4537],
  荆州市: [112.2414, 30.3362],
  恩施土家族苗族自治州: [109.488, 30.2721],
  神农架林区: [110.6758, 31.7451],
  随州市: [113.3823, 31.6902],
  黄石市: [115.0389, 30.201],
  鄂州市: [114.8949, 30.3914],
  锦州市: [121.1268, 41.0961],
  大连市: [121.6147, 38.9139],
  营口市: [122.2191, 40.625],
  葫芦岛市: [120.8367, 40.7109],
  抚顺市: [123.957, 41.88131],
  沈阳市: [123.4646, 41.6775],
  丹东市: [124.3544, 40.0006],
  铁岭市: [123.726, 42.2237],
  阜新市: [121.670, 42.022],
  本溪市: [123.6849, 41.4868],
  鞍山市: [122.9941, 41.1082],
  辽阳市: [123.2396, 41.2673],
  盘锦市: [122.1707, 40.7195],
  朝阳市: [120.4888, 41.6018],
  汕头市: [116.6819, 23.3541],
  佛山市: [113.1215, 23.0213],
  肇庆市: [112.4652, 23.0477],
  惠州市: [114.4155, 23.1123],
  深圳市: [114.0579, 22.5435],
  珠海市: [113.5768, 22.2716],
  湛江市: [110.3575, 21.2701],
  揭阳市: [116.3727, 23.5509],
  阳江市: [111.9834, 21.8568],
  潮州市: [116.6219, 23.6576],
  河源市: [114.7002, 23.7442],
  云浮市: [112.0445, 22.9151],
  汕尾市: [115.3755, 22.7872],
  韶关市: [113.5973, 24.8109],
  江门市: [113.0815, 22.5789],
  茂名市: [110.9255, 21.6627],
  清远市: [113.056, 23.682],
  梅州市: [116.1220, 24.2888],
  广州市: [113.2644, 23.13],
  中山市: [113.3925, 22.517],
  东莞市: [113.7518, 23.021],
  包头市: [109.9531, 40.6213],
  呼伦贝尔市: [119.7784, 49.1665],
  巴彦淖尔市: [107.3877, 40.7429],
  乌海市: [106.7941, 39.655],
  阿拉善盟: [105.7291, 38.8515],
  通辽市: [122.2433, 43.6535],
  兴安盟: [122.0377, 46.0823],
  锡林郭勒盟: [116.0473, 43.9332],
  呼和浩特市: [111.7488, 40.8421],
  乌兰察布市: [113.1322, 40.9945],
  鄂尔多斯市: [109.7824, 39.6087],
  赤峰市: [118.8876, 42.2568],
  鹤岗市: [130.2976, 47.3506],
  大兴安岭地区: [124.1178, 50.4112],
  七台河市: [131.003, 45.7711],
  哈尔滨市: [126.535, 45.8029],
  佳木斯市: [130.3189, 46.8],
  双鸭山市: [131.1415, 46.6761],
  黑河市: [127.5282, 50.2448],
  牡丹江市: [129.6329, 44.5514],
  齐齐哈尔市: [123.9181, 47.3548],
  鸡西市: [130.9693, 45.295],
  大庆市: [125.104, 46.5894],
  绥化市: [126.9687, 46.6541],
  伊春市: [128.8408, 47.7283],
  洛阳市: [112.4538, 34.6197],
  三门峡市: [111.2004, 34.7731],
  漯河市: [114.0166, 33.5803],
  许昌市: [113.852, 34.0373],
  南阳市: [112.5285, 32.9902],
  信阳市: [114.091, 32.1486],
  济源市: [112.6023, 35.069],
  濮阳市: [115.0292, 35.7627],
  鹤壁市: [114.2973, 35.7483],
  郑州市: [113.6253, 34.7463],
  焦作市: [113.2419, 35.2157],
  安阳市: [114.3924, 36.0987],
  开封市: [114.3142, 34.798],
  商丘市: [115.6563, 34.4151],
  新乡市: [113.9267, 35.3035],
  驻马店市: [114.0219, 33.014],
  周口市: [114.7012, 33.6346],
  平顶山市: [113.1925, 33.7665],
  威海市: [122.1205, 37.5133],
  淄博市: [118.0549, 36.8137],
  青岛市: [120.3826, 36.0669],
  烟台市: [121.4477, 37.4645],
  聊城市: [115.9852, 36.4558],
  东营市: [118.6746, 37.4339],
  滨州市: [117.9707, 37.3826],
  日照市: [119.5268, 35.4169],
  潍坊市: [119.1617, 36.7076],
  济南市: [117.1201, 36.652],
  泰安市: [117.0869, 36.2017],
  枣庄市: [117.3237, 34.8108],
  德州市: [116.3592, 37.4364],
  济宁市: [116.5871, 35.4151],
  临沂市: [118.3564, 35.1037],
  菏泽市: [115.4796, 35.2343],
  汉中市: [107.0231, 33.0663],
  商洛市: [109.9186, 33.8733],
  榆林市: [109.7341, 38.2857],
  铜川市: [108.9451, 34.8971],
  延安市: [109.4946, 36.6501],
  西安市: [108.9396, 34.3432],
  宝鸡市: [107.2376, 34.3628],
  安康市: [109.029, 32.6854],
  咸阳市: [108.7088, 34.3298],
  渭南市: [109.4709, 34.5206],
  黔东南苗族侗族自治州: [107.9828, 26.5837],
  遵义市: [107.0319, 27.7219],
  六盘水市: [104.8303, 26.5925],
  铜仁市: [109.1895, 27.7315],
  黔西南布依族苗族自治州: [104.9064, 25.0877],
  安顺市: [105.9476, 26.2531],
  黔南布依族苗族自治州: [107.5223, 26.2531],
  毕节市: [105.2915, 27.2836],
  贵阳市: [106.6282, 26.6466],
  上海市: [121.4726, 31.2317],
  重庆市: [108.1702, 29.2919],
  林芝市: [94.3614, 29.6487],
  昌都市: [97.1704, 31.1428],
  那曲市: [92.0515, 31.4779],
  拉萨市: [91.1719, 29.6534],
  阿里地区: [80.1057, 32.5009],
  山南市: [91.7714, 29.2377],
  日喀则市: [88.8804, 29.2668],
  马鞍山市: [118.506, 31.6687],
  阜阳市: [115.8142, 32.891],
  铜陵市: [117.8112, 30.9452],
  池州市: [117.4956, 30.6742],
  黄山市: [118.3376, 29.7148],
  安庆市: [117.1153, 30.5318],
  淮南市: [117.0186, 32.5853],
  蚌埠市: [117.3885, 32.9168],
  亳州市: [115.7785, 33.8462],
  宣城市: [118.7591, 30.9392],
  六安市: [116.5197, 31.7358],
  芜湖市: [118.4330, 31.3526],
  宿州市: [116.9641, 33.6472],
  淮北市: [116.7983, 33.9562],
  合肥市: [117.2272, 31.8205],
  滁州市: [118.3334, 32.2559],
  福州市: [119.2964, 26.0742],
  宁德市: [119.5477, 26.6662],
  莆田市: [119.0076, 25.4542],
  南平市: [118.0813, 27.3828],
  厦门市: [118.0889, 24.4796],
  泉州市: [118.6757, 24.8744],
  漳州市: [117.6472, 24.5152],
  三明市: [117.6389, 26.2634],
  龙岩市: [117.0173, 25.0758],
  岳阳市: [113.1289, 29.3564],
  湘西土家族苗族自治州: [109.7374, 28.3125],
  长沙市: [112.9388, 28.2283],
  常德市: [111.699, 29.0314],
  张家界市: [110.4788, 29.1173],
  怀化市: [110.0015, 27.5698],
  湘潭市: [112.9454, 27.8313],
  株洲市: [113.1327, 27.8288],
  邵阳市: [111.4678, 27.2395],
  郴州市: [113.0155, 25.7701],
  娄底市: [111.9944, 27.6998],
  益阳市: [112.3559, 28.5548],
  衡阳市: [112.572, 26.8942],
  永州市: [111.6134, 26.4198],
  临高县: [109.6905, 19.9120],
  定安县: [110.3580, 19.6812],
  琼海市: [110.4745, 19.2591],
  陵水黎族自治县: [110.0375, 18.506],
  白沙黎族自治县: [109.4429, 19.2216],
  屯昌县: [110.1016, 19.3516],
  东方市: [108.6518, 19.0951],
  乐东黎族自治县: [109.1733, 18.75],
  昌江黎族自治县: [109.0557, 19.2981],
  万宁市: [110.3926, 18.7936],
  琼中黎族苗族自治县: [109.8384, 19.0332],
  三沙市: [112.3386, 16.831],
  澄迈县: [110.0074, 19.7388],
  文昌市: [110.7974, 19.5442],
  三亚市: [109.5117, 18.2528],
  儋州市: [109.5808, 19.5209],
  海口市: [110.1984, 20.0458],
  保亭黎族苗族自治县: [109.7002, 18.6403],
  五指山市: [109.5167, 18.7748],
  连云港市: [119.2214, 34.5966],
  南通市: [120.8945, 31.9812],
  宿迁市: [118.2752, 33.9631],
  南京市: [118.7966, 32.0593],
  淮安市: [119.1131, 33.5514],
  常州市: [119.9740, 31.8113],
  镇江市: [119.4244, 32.1881],
  泰州市: [119.9228, 32.4566],
  盐城市: [120.1626, 33.3481],
  扬州市: [119.4128, 32.3944],
  无锡市: [120.3118, 31.491],
  苏州市: [120.5852, 31.2997],
  徐州市: [117.2837, 34.2042],
  海西蒙古族藏族自治州: [97.3319, 37.3481],
  海南藏族自治州: [100.6226, 36.2963],
  海东市: [102.4106, 36.4734],
  玉树藏族自治州: [97.0062, 33.0063],
  黄南藏族自治州: [102.0153, 35.5193],
  果洛藏族自治州: [100.2451, 34.4721],
  西宁市: [101.7777, 36.6166],
  海北藏族自治州: [100.9009, 36.9546],
  柳州市: [109.4280, 24.3264],
  钦州市: [108.6543, 21.9808],
  北海市: [109.1202, 21.4813],
  百色市: [106.6186, 23.903],
  梧州市: [111.279, 23.4767],
  桂林市: [110.1797, 25.2356],
  贺州市: [111.5672, 24.4041],
  来宾市: [109.2212, 23.7501],
  河池市: [108.0852, 24.6929],
  玉林市: [110.1809, 22.654],
  南宁市: [108.3664, 22.8177],
  崇左市: [107.3649, 22.3771],
  贵港市: [109.5989, 23.1118],
  防城港市: [108.3546, 21.6867],
  固原市: [106.2426, 36.0162],
  中卫市: [105.1967, 37.5001],
  石嘴山市: [106.3827, 38.9846],
  银川市: [106.2309, 38.4877],
  吴忠市: [106.1986, 37.9977],
  宁波市: [121.6245, 29.8602],
  温州市: [120.6992, 27.9938],
  嘉兴市: [120.7556, 30.7468],
  舟山市: [122.2073, 29.9855],
  台州市: [121.4207, 28.6557],
  丽水市: [119.9232, 28.4676],
  金华市: [119.6472, 29.0791],
  衢州市: [118.8593, 28.9702],
  湖州市: [120.0868, 30.8941],
  杭州市: [120.2107, 30.2460],
  绍兴市: [120.5828, 30.0515],
  唐山市: [118.1801, 39.6306],
  秦皇岛市: [119.5202, 39.8882],
  邯郸市: [114.5391, 36.6258],
  廊坊市: [116.6835, 39.5383],
  沧州市: [116.8387, 38.3046],
  邢台市: [114.4974, 37.0602],
  衡水市: [115.6689, 37.7393],
  张家口市: [114.8858, 40.7689],
  石家庄市: [114.5149, 38.0420],
  保定市: [115.4645, 38.8744],
  承德市: [117.9627, 40.9529],
  香港: [114.1733, 22.32],
  澳门: [113.549, 22.1989],
  嘉峪关市: [98.2882, 39.7732],
  酒泉市: [98.4943, 39.7334],
  兰州市: [103.8342, 36.0607],
  金昌市: [102.1879, 38.5214],
  白银市: [104.1388, 36.5451],
  平凉市: [106.6649, 35.5424],
  张掖市: [100.4498, 38.9247],
  庆阳市: [107.6434, 35.7094],
  武威市: [102.6378, 37.9289],
  甘南藏族自治州: [102.9117, 34.9832],
  临夏回族自治州: [103.2103, 35.6017],
  定西市: [104.5923, 35.6079],
  天水市: [105.7248, 34.5815],
  陇南市: [104.9602, 33.3701],
  广元市: [105.844, 32.4357],
  南充市: [106.1105, 30.8372],
  绵阳市: [104.6791, 31.4676],
  巴中市: [106.7475, 31.8678],
  德阳市: [104.3977, 31.1274],
  成都市: [104.0663, 30.5729],
  内江市: [105.0579, 29.5802],
  自贡市: [104.7793, 29.3392],
  广安市: [106.6326, 30.4563],
  资阳市: [104.6272, 30.1292],
  达州市: [107.4677, 31.2092],
  遂宁市: [105.5926, 30.5326],
  乐山市: [103.7660, 29.5522],
  泸州市: [105.4418, 28.8709],
  眉山市: [103.8484, 30.0771],
  宜宾市: [104.6428, 28.7523],
  凉山彝族自治州: [102.2677, 27.8813],
  攀枝花市: [101.7184, 26.5824],
  甘孜藏族自治州: [101.9623, 30.0495],
  阿坝藏族羌族自治州: [102.2245, 31.8994],
  雅安市: [103.0415, 30.0099],
  天津市: [117.2015, 39.0853],
  赣州市: [114.9334, 25.8311],
  景德镇市: [117.1848, 29.2744],
  萍乡市: [113.8871, 27.6587],
  新余市: [114.9166, 27.8185],
  吉安市: [114.9668, 27.0912],
  上饶市: [117.9430, 28.4551],
  南昌市: [115.8579, 28.6829],
  抚州市: [116.3580, 27.9489],
  鹰潭市: [117.0395, 28.2720],
  九江市: [115.95356, 29.661],
  宜春市: [114.4168, 27.8162],
  曲靖市: [103.7962, 25.4908],
  昭通市: [103.7170, 27.3381],
  红河哈尼族彝族自治州: [103.3748, 23.3631],
  怒江傈僳族自治州: [98.8566, 25.8175],
  西双版纳傣族自治州: [100.797, 22.009],
  丽江市: [100.2259, 26.8551],
  文山壮族苗族自治州: [104.2156, 23.4009],
  玉溪市: [102.5266, 24.3467],
  普洱市: [100.9660, 22.8252],
  保山市: [99.1614, 25.112],
  大理白族自治州: [100.2676, 25.6065],
  楚雄彝族自治州: [101.5283, 25.0456],
  迪庆藏族自治州: [99.7021, 27.8191],
  临沧市: [100.0888, 23.8841],
  昆明市: [102.8336, 24.8814],
  德宏傣族景颇族自治州: [98.5856, 24.4331],
  太原市: [112.5496, 37.8704],
  阳泉市: [113.5804, 37.8570],
  吕梁市: [111.1445, 37.5189],
  晋中市: [112.7526, 37.688],
  长治市: [113.1173, 36.1951],
  临汾市: [111.5193, 36.0885],
  运城市: [111.0070, 35.0266],
  忻州市: [112.7341, 38.4159],
  朔州市: [112.4329, 39.3317],
  大同市: [113.3667, 40.0971],
  晋城市: [112.8520, 35.4913],
  北京市: [116.4551, 40.2539],
  松原市: [124.8253, 45.1419],
  长春市: [125.3236, 43.8169],
  吉林市: [126.5497, 43.8381],
  白城市: [122.8381, 45.6201],
  辽源市: [125.1446, 42.8879],
  四平市: [124.3505, 43.1667],
  延边朝鲜族自治州: [129.4706, 42.9094],
  白山市: [126.4142, 41.9441],
  通化市: [125.9397, 41.7283]
}


